footer{
    background-color: #0F594E;
}
footer ul{
    margin:0;
    padding:0;
    list-style:none;
}

footer  ul:first-child li{
    padding: 5px 0;
}

footer li a{
    color:#80BFB3 !important;
    text-decoration: none;
    text-transform: uppercase;
}
footer li a:hover{
    color:#FFF !important;
}
footer h6{
    margin-bottom: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color:#80BFB3;
}

footer ul:first-child li a{
    font-weight: 700;
}

footer ul:first-child + h6{
    margin-top: 20px;
    margin-bottom:5px;
}
.servicios_menu li a{
    text-transform: capitalize !important;
}
footer p{
    color:#80BFB3;
}
/* footer .fabrands{
    color:#80BFB3 !important;
} */
footer .contact_emails a{
    text-transform: lowercase !important;
}

footer hr, span, address{
    color:#80BFB3;
}
