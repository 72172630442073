#title{
    padding-top: 120px;
    background-color:#FFF;
}

#title h1{
    font-weight:900
}

#requisitos, #beneficios, #caracteristicas, #solicitar{
    background-color: #F2F3F7;
}
#caracteristicas ul, #caracteristicas ol,
#beneficios ul, #beneficios ol,
#requisitos ul, #requisitos ol{
    margin: 0;
    padding: 1rem;
}

#logoimagen{
    width:100px;
    height: auto;
}

.banner{
    position:relative;
}

.banner::before{
    content:attr(data-overlay) " ";
    background-color:rgba(0,0,0,.8);
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
}

#creditos{
    background-size: cover;
}

#creditos h3,
#ahorro h3{
    font-weight: 700;
}
#creditos h2{
    font-weight: 700;
    color:#FFF;
}
#ahorro h2{
    font-weight: 700;
    color:#000;
}
#creditos p{
    color:#FFF;
}
#ahorro{
    background-color: #F2F3F4;
}

.subtitle{
    font-size: 1.4rem;
}
.inner{
    background-color:#FFF;
    border:none !important;
    border-radius: 20px;
    text-align: center;
    min-height: 371px;
}
.inner p{
    color:#666666 !important;
}
.inner img{
    
}

.inner:hover{
    /* background-color: #FDDE0204; */
    /* box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important; */
}
.button{
    padding-left: 15px !important;
    padding-right: 15px !important;
    border-radius: calc(120px / 2);
    transition: 0.3s;
    text-decoration:none;
    padding: 10px;
    max-width: 170px;
    text-align: center;
}

.button:hover{
    background-color: #F2F3F5 !important;
    color:#2A9C63 !important;
}

.greenButton {
    background-color:#2A9C63;
    color:#FFF !important;
}


.greenButton:hover{
    background-color:#000 !important;
    color:#FFF !important;
}