
body{
    font-family: 'Montserrat', sans-serif !important;
}
a{
    color: #2A9C63 !important;
}

.nav-link{
    transition: 0.3s;
}
.nav-link:hover{
    background-color:#F2F3F5 ;
    //color:#FFF !important;
    opacity: 1
    //font-weight: 800;
}
.nav-link.active{
    background-color: #F2F3F5;
    //color:#FFF !important;
    font-weight: 800;
}
.navbar .container{
    background: rgba(255,255,255,.9);
}
.fixed-top{
    background: rgba(255,255,255,.7);
}
.fixed-top-green{
    background: rgba(42, 156, 99,0.9);
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}
.fixed-top-green .nav-link{
    color:#FFF !important;
}
.fixed-top-green .nav-link.active,
.fixed-top-green .nav-link:hover{
    color:rgb(42, 156, 99) !important;
}
#primarySlider{
    margin-top: 120px;
}

#primaryCarousel{
    height: 100vh;
}

#servicesCarousel .carousel-caption{
    text-align: left !important;
}
.carousel-item.active{
    display:flex !important;
    align-items: center;
    flex-direction: row;
    // height: 100vh;
    overflow:hidden;
}

#primaryCarousel .carousel-item.active{
    height: 100vh;
}
.carousel-indicators{
    //bottom: -50px !important;
}
.carousel-indicators [data-bs-target]{
 background-color: #FFDE00 !important;
}
address{
    margin-bottom: 0 !important;
}
.was-validated .form-select:invalid, .form-select.is-invalid,
.form-control.is-invalid{
    border-color:#dc3545 !important;
}

// #navbarSupportedContent.show{
//     background: rgba(255, 255, 255, 1);
// }

@media only screen and (min-width: 320px) {
    .navbar .container{
        height: 90px;
    }
    .navbar .container,
    #navbarSupportedContent.show{
        background: rgba(255, 255, 255, 1);
    }
    #services_carousel{
        padding-bottom: 3rem !important;
    }
    .nav-link{
        padding-left: 15px !important;
        padding-right: 15px !important;
        border-radius: 0;
    }
   
    .navbar li {
        margin-left: 0;
    }
    // .navbar-toggler{
    //     color:#FFF;
    // }
    // .carousel-indicators{
    //     bottom: -10px !important;
    // }
//     #servicesCarousel .carousel-indicators{
//         bottom: 200px !important;
//    } 
    footer img{
        width: 40%;
    }
}
@media only screen and (min-width: 600px) {
    .navbar .container{
        height: 120px;
    }
    .navbar .container,
    #navbarSupportedContent.show{
        background: rgba(255, 255, 255, .95);
    }
    #services_carousel{
        padding-bottom: 6rem !important;
    }
    .navbar li {
        margin-left: 15px;
    }
    // #servicesCarousel .carousel-indicators{
    //     bottom: 130px !important;
    // }
    
}

@media (min-width: 768px) {
    .nav-link{
        padding-left: 15px !important;
        padding-right: 15px !important;
        border-radius: 20px;
    }
    
   footer img{
    width: 20%;
  }
}

@media (min-width: 1200px) { 
//     #servicesCarousel .carousel-caption{
//         // bottom: 12.7rem !important;
//    } 
//    #servicesCarousel .carousel-indicators{
//     bottom: 50px !important;
// } 
   footer img{
    width: 40%;
  }
}



@import '~bootstrap/scss/bootstrap.scss';