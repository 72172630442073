#primarySlider{
    height:100vh;
    overflow:hidden;
}
.sliderTitle{
    color: #FFF;
    font-weight:900;
    text-transform:uppercase;
}
.sliderSubTitle{
    color: #FFF;
    font-weight:500;
    text-transform:uppercase;
}
.button{
    padding-left: 15px !important;
    padding-right: 15px !important;
    border-radius: calc(120px / 2);
    transition: 0.3s;
    text-decoration:none;
    padding: 10px;
    max-width: 170px;
    text-align: center;
}

.button:hover{
    background-color: #F2F3F5 !important;
    color:#2A9C63 !important;
}

.greenButton {
    background-color:#2A9C63;
    color:#FFF !important;
}


.grayButton:hover{
    background-color:#2A9C63 !important;
    color:#FFF !important;
}
.grayButton{
    background-color: #CFD1D3;
    color:#FFF !important;
}
.whiteButton {
    background-color:#FFFFFF;
    color:#2A9C63 !important;
}

.sliderCaption, .servicesCaption{
    
    align-self: center;
   
    /* margin: 0 auto; */
    display: flex !important;
    justify-content: center;
    flex-direction: column;
    text-align: left;
        /* left: 10% !important;
        right:10% !important; */
        position: absolute !important;
        bottom:0;
        top:0;
        margin: auto;
}

.servicesCarousel h5{
    font-weight: 700;
}
.servicesCarousel .carousel-caption{
    text-align: left !important;
}

.about_logo{
    width:250px;
    height: 106.3px;
}

#about_section{
    background-size: cover;
    background-repeat: no-repeat;
}
#afiliarse_section{
    /* height:600px; */
}
#afiliarse_section{
    background-repeat: no-repeat;
    background-color:#19594e;
    background-position-x: center;
}
#about_section h5,
#afiliarse_section h5{
    color:#FFF;
    text-align: center;
}
#about_section h5{
    font-weight: 600;
    font-size: 2.6rem;
}
#afiliarse_section h5{
    font-weight: 600;
}
#about_section p{
    color:#FFF;
    font-weight: 400;
    font-size: 1.2rem;
    text-align: center;
}
.about_sectionInner{
    padding-top: 74px;
    padding-bottom:74px;
    background-position: top !important;
}
.afiliarse_sectionInner{
    background-size: cover;
}
#noticiasHome a{
  text-decoration:none;
  color:#202223 !important;
}
#noticiasHome a:hover h5{
    color:#2A9C63;
}
#productos_servicios,
#noticiasHome,
.servicesCarousel,
#productosServiciosSlider{
    background-color: #F2F3F4;
}

#productos_servicios h2,
#noticiasHome h2{
    font-weight: 900;
    color:#202223 !important;
}
#noticiasHome .card{
    overflow: hidden;
}
.card_noticia{
    overflow: hidden;
}
.inner_servicio{
    background-color: #FFF;
    border-radius: 10px;
    height:500px;
    text-align: center;
}
.inner_servicio_p{
    color:#666666;
}
.inner_servicio_h3{
    font-weight: 800;
}
.inner_servicio_pGreen{
    color:#2A9C63;
}
.backgroundCover{
    background-size: cover;
    background-position: center center;
    width: 100%;
}


@media only screen and (min-width: 320px) {
    .sliderCaption{
        left: 20% !important;
        right:20% !important;
        position: absolute !important;
        bottom:0%;
        top:0;
    }
    .activeSlider{
        height:400px;
    }
    .servicesCarousel h5{
        text-align: center;
        align-self: center;
    }
    .greenButton{
        font-size: 12px;
    }
    .servicesCarousel .greenButton{
        display: flex;
        text-align: center;
        align-self: center;
    }
    .sliderTitle{
        font-size: .85rem;
    }
    .sliderSubTitle{
        font-size: .6rem;
    }
    .sliderCaption .button{
        /* width: 100px; */
        padding:5px;
    }
    #afiliarse_section{
        height:300px;
    }
    #primarySlider{
        /* margin-top:90px; */
    }
    .backgroundCover{
        height: 250px;
    }
    #about_section h5,
    #afiliarse_section h5{
        font-size: 1.3rem;
    }
    #productos_servicios h3{
        font-size: 1.4rem !important;
    }
    #productos_servicios img{
        height: 125px;
    }
    #noticiasHome h2{
        font-size: 1.4rem !important;
    }
    #noticiasHome  h5{
        font-size: 1rem;
    }
    
}

/* @media (max-width: 576px) { 
    .sliderCaption{
        padding: 0;
        bottom: 20%;
    }
 } */

@media (min-width: 600px) {
    .sliderCaption{
        left: 20% !important;
        right:20% !important;
        position: absolute !important;
        bottom:0%;
        top:0;
    }
    /* .servicesCarousel{
        height:700px;
    } */
    .activeSlider{
        height:700px;
    }
    
    .servicesCarousel h5, .servicesCarousel .greenButton{
        align-self: flex-start;
    }
    #primarySlider{
        /* margin-top:120px; */
    }
    #afiliarse_section{
        height:600px;
    }
    .sliderTitle{
        font-size: 2rem !important;
    }
    .sliderSubTitle{
        font-size: 1.4rem;
    }
    .backgroundCover{
        height: 100%;
    }
    .greenButton{
        font-size: 18px;
    }
    #about_section h5{
        font-size: 2.5rem;
    }
    /* #productos_servicios h2,  */
    #noticiasHome h2{
        font-size: 2.6rem !important;
    }
    #afiliarse_section h5{
        font-size: 3rem;
    }
}

@media (min-width: 768px) { 
    .sliderTitle{
        font-size: 2.1rem !important;
    }
    .sliderCaption{
        left: 20% !important;
        right:20% !important;
        position: absolute !important;
        bottom:0;
        top:0;
        margin: auto;
    }
    #noticiasHome  h5{
        font-size: .8rem;
    }
    /* #productos_servicios h2,  */
    #noticiasHome h2{
        font-size: 1.8rem !important;
    }
    #noticiasHome  h5{
        font-size: 1rem;
    }
    
 }

 @media (min-width: 992px) { 
    .sliderTitle{
        font-size: 2.58rem !important;
    }
    .sliderCaption{
        left: 120px !important;
        /* right: 120px !important; */
        position: absolute !important;
        top: 0;
    }
    #noticiasHome h2{
        font-size: 2.6rem !important;
    }
    #noticiasHome  h5{
        font-size: .8rem;
    }
    #productos_servicios h2, 
    #noticiasHome h2{
        font-size: 2.6rem !important;
    }
    #noticiasHome  h5{
        font-size: 1.5rem;
    }
    #afiliarse_section h5{
        font-size: 4rem;
    }
  }

  @media (min-width: 1000px) { 
    .sliderCaption{
        left: 20% !important;
        right: 20% !important;
        position: absolute !important;
        top: 0;
        bottom:0;
        margin: auto;
    }
    .sliderTitle{
        font-size: 2.80rem !important;
    }
    #productos_servicios h3{
        font-size: 2rem !important;
    }
    #noticiasHome  h5{
        font-size: 1.2rem;
    }
    .backgroundCover{
        height:200px;
        overflow: hidden;
    }
    #about_section{
        height: 700px;
    }
    .card_noticia{
        height: 200px;
    }

   }
   @media (min-width: 1440px) { 
    .sliderTitle{
        font-size: 3.8rem !important;
    }
   }

   @media (min-width: 1441px) { 
    .sliderTitle{
        font-size: 5.1rem !important;
    }
   }
   