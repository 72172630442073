#nosotrosIntro{
    padding-top: 120px;
    background-color: #F2F3F4;
}

#nosotrosIntro h1{
    font-weight:900
}
#nosotrosIntro h2{
    font-weight: 700;
}
#nosotrosIntro h5{
    font-weight: 700;
}

#nosotrosIntro p{
    color:#00594E;
    line-height: 1.8em;
    font-size: 1.2rem;
}
.inner{
    background-color:#FFF;
    border:none !important;
    border-radius: 20px;
}
.inner p{
    color:#666666 !important;
    font-size: 1rem !important;
}
.inner ul{
    padding:0;
    margin:0;
    list-style-type:none;
    
}
.inner li{
    font-size: 1rem !important;
    color:#666666 !important;
}
.inner h3{
    font-weight: 700;
}

#organizacion{
    background-size: cover;
    background-position: center;
}

#futuro h5{
    font-weight: 600;
    /*font-size: 4rem;*/
    color:#00594E;
}

@media only screen and (min-width: 320px) {
    #futuro h5{
        font-size: 2rem;
    }
}

@media only screen and (min-width: 600px) {
    #futuro h5{
        font-size: 3rem;
    }
}

@media only screen and (min-width: 992px) {
    #futuro h5{
        font-size: 4rem;
    }
}