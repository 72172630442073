#title, #form{
    background-color: #F2F3F4;
}

#title h1{
    font-weight:900
}
#form h6{
    font-weight: 400;
    color:#666666;
}
#form ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
}
#form li{
    font-weight: 700;
}
#form p{
    color:#000;
    font-weight: 700;
}

#sugerencias{
    background-size: cover;
}

#sugerencias h2{
    color:#FFF;
    font-weight: 900;
}

.subtitle{
    font-size: 1.4rem;
}
.inner{
    background-color:#FFF;
    border:none !important;
    border-radius: 20px;
}
.inner p{
    color:#666666 !important;
}