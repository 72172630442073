#title{
    padding-top: 120px;
    background-color: #F2F3F4;
}

#title h1{
    font-weight:900
}

#creditos{
    background-size: cover;
}

#creditos h3,
#ahorro h3{
    font-weight: 700;
}
#creditos h2{
    font-weight: 700;
    color:#FFF;
}
#ahorro h2{
    font-weight: 700;
    color:#000;
}
#creditos p{
    color:#FFF;
}
#ahorro{
    background-color: #F2F3F4;
}

.subtitle{
    font-size: 1.4rem;
}
.inner{
    background-color:#FFF;
    border:none !important;
    border-radius: 20px;
    text-align: center;
    min-height: 371px;
}
.inner p{
    color:#666666 !important;
}
.inner img{
    width: auto;
    height: 152px;
}

.inner:hover{
    /* background-color: #FDDE0204; */
    /* box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important; */
}