#title{
    padding-top: 120px;
    background-color: #F2F3F4;
}

#title h1{
    font-weight:900;
    color:#00594E;
}

#title p{
    font-weight: 400;
    color:#666666;
}

#asociarse,
#acuerdo,
#relacionComercial{
    background-color: #F2F3F4;
}

#acuerdo p{
    text-align: justify;
}

#selectProductos{
    background-color: #10594E;
}
#selectProductos h2{
    color:#FFF;
    font-weight: 900;
}

#selectProductos label{
    color:#FFF;
    font-weight: 700;
}
#relacionComercial p{}

.inner{
    background-color:#FFF;
    border:none !important;
    border-radius: 20px;
    text-align: center;
    min-height: 371px;
}
.inner p{
    color:#666666 !important;
}
.inner h2{
    font-weight: 700;
    font-size: 1.5rem;
}
#productos_to_select{
    column-width: 200px;
    list-style-type: none;
}
#productos_to_select li{
    display: block;
}

@media (min-width: 320px) { 
    #productos_to_select{
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }
 }

 @media (min-width: 576px) { 
    #productos_to_select{
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
 }
 @media (min-width: 768px) { 
    #productos_to_select{
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
    }
 }

